import { h } from 'snabbdom';
export const userLink = (u) => h('a', {
    // can't be inlined because of thunks
    class: { 'user-link': true, ulpt: u.name != 'ghost', online: !!u.online },
    attrs: { href: `/@/${u.name}`, ...u.attrs },
}, [userLine(u), ...fullName(u), u.rating && ` ${userRating(u)} `]);
export const userFlair = (u) => u.flair ? h('img.uflair', { attrs: { src: site.asset.flairSrc(u.flair) } }) : undefined;
export const userLine = (u) => u.line !== false
    ? h('i.line', {
        class: { patron: !!u.patron, moderator: !!u.moderator },
        attrs: u.patron ? { title: 'Lichess Patron' } : {},
    })
    : undefined;
export const userTitle = (u) => u.title
    ? h('span.utitle', u.title == 'BOT' ? { attrs: { 'data-bot': true } } : {}, [u.title, '\xa0'])
    : undefined;
export const fullName = (u) => [userTitle(u), u.name, userFlair(u)];
export const userRating = (u) => {
    if (u.rating) {
        const rating = `${u.rating}${u.provisional ? '?' : ''}`;
        return u.brackets !== false ? `(${rating})` : rating;
    }
    return undefined;
};
export const ratingDiff = (u) => u.ratingDiff === 0
    ? h('span', '±0')
    : u.ratingDiff && u.ratingDiff > 0
        ? h('good', '+' + u.ratingDiff)
        : u.ratingDiff && u.ratingDiff < 0
            ? h('bad', '−' + -u.ratingDiff)
            : undefined;
